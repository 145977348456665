import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import cx from 'classnames'; // for conditionally applying classes

import pt from 'assets/pt.svg';
import en from 'assets/en.svg';

import TheSpacer from '../spacer/thespacer';

const useStyles = createUseStyles({
  hamburgerContainer: {
    position: 'relative',
    zIndex: 999,
  },
  hamburgerIcon: {
    cursor: 'pointer',
    width: 35,
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: 10,
    '& svg': {
      width: 35,
      height: 35,
    },
    '&.open': {
      color: 'white',
    },
  },
  menu: {
    position: 'fixed',
    top: '-100vh',
    left: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(46, 41, 35, 0.9)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '0.5s ease',
  },
  menuOpen: {
    top: 0,
  },
  navigation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  navItem: {
    color: 'white',
    fontSize: 24,
    textDecoration: 'none',
    position: 'relative',
    border: '2px solid rgba(57, 255, 137, 0)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '600',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  current: {
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: 'var(--green)',
    '&.artists': {
      backgroundColor: 'var(--red)',
    },
  },

  pageTitle: {
    color: '#fff',
    fontSize: 18,
    fontWeight: '600',
    letterSpacing: '0.6px',
    marginRight: '40px',
    '&.artists': {
      color: '#242424',
    },
    '&.partners': {
      color: '#242424',
    },
  },
});

const HamburgerMenu = ({ home, tickets, artists, conference, partners, news, faqs, link }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const classes = useStyles();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const location = useLocation();
  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}
    >
      <p className={cx(classes.pageTitle, { artists: artists, partners: partners })}>
        {tickets && (lang === 'en' ? 'Tickets' : 'Bilhetes')}
        {artists && (lang === 'en' ? 'Artists' : 'Artistas')}
        {conference && (lang === 'en' ? 'Conference' : 'Conferência')}
        {partners && (lang === 'en' ? 'Partners' : 'Parceiros')}
        {news && (lang === 'en' ? 'News' : 'Notícias')}
        {faqs && 'FAQs'}
      </p>
      <div className={classes.hamburgerContainer}>
        {/* Hamburger Icon */}
        <div className={cx(classes.hamburgerIcon, { "open": menuOpen })} onClick={toggleMenu}>
          {menuOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="3" y1="12" x2="21" y2="12" />
              <line x1="3" y1="6" x2="21" y2="6" />
              <line x1="3" y1="18" x2="21" y2="18" />
            </svg>
          )}
        </div>

        {/* Menu */}
        <div className={`${classes.menu} ${menuOpen ? classes.menuOpen : ''}`}>
          <div className={classes.navigation}>
            <div className={classes.navItem}>
              <Link to={lang === 'en' ? '/' : '/pt'}>Home</Link>
              {home && <div className={classes.current} />}
            </div>
            <TheSpacer size={16} vertical />
            <div className={classes.navItem}>
              <Link to={lang === 'en' ? '/tickets' : '/pt/bilhetes'}>{lang === 'en' ? 'Tickets' : 'Bilhetes'}</Link>
              {tickets && <div className={classes.current} />}
            </div>
            <TheSpacer size={16} vertical />
            <div className={cx(classes.navItem, { artists })}>
              <Link to={lang === 'en' ? '/artists' : '/pt/artistas'}>{lang === 'en' ? 'Artists' : 'Artistas'}</Link>
              {artists && <div className={cx(classes.current, 'artists')} />}
            </div>
            <TheSpacer size={16} vertical />
            <div className={cx(classes.navItem, { conference })}>
              <Link to={lang === 'en' ? '/conference' : '/pt/conferencia'}>
                {lang === 'en' ? 'Conference' : 'Conferência'}
              </Link>
              {conference && <div className={classes.current} />}
            </div>
            <TheSpacer size={16} vertical />
            <div className={cx(classes.navItem, { partners })}>
              <Link to={lang === 'en' ? '/partners' : '/pt/parceiros'}>
                {lang === 'en' ? 'Partners' : 'Parceiros'}
              </Link>
              {partners && <div className={classes.current} />}
            </div>
            <TheSpacer size={16} vertical />
            <div className={classes.navItem}>
              <Link to={lang === 'en' ? '/news' : '/pt/noticias'}>{lang === 'en' ? 'News' : 'Notícias'}</Link>
              {news && <div className={classes.current} />}
            </div>
            <TheSpacer size={16} vertical />
            <div className={classes.navItem}>
              <Link to={lang === 'en' ? '/faqs' : '/pt/faqs'}>FAQs</Link>
              {faqs && <div className={classes.current} />}
            </div>
            <TheSpacer size={40} vertical />
            {lang === 'en' ? (
              <Link className={classes.heroLanguageChanger} to={link} onClick={toggleMenu}>
                <img src={pt} alt="pt" />
              </Link>
            ) : (
              <Link className={classes.heroLanguageChanger} to={link} onClick={toggleMenu}>
                <img src={en} alt="en" />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HamburgerMenu;
