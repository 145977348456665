import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation, Link } from 'react-router-dom';
import cx from 'classnames';
import useStyles from './styles';
import pt from 'assets/pt.svg';
import en from 'assets/en.svg';
import investidor from 'assets/partners/braga25/braga25.png';
import investidorRed from 'assets/partners/braga25/braga25-red.png';
import investidorPT from 'assets/partners/braga25/logo_braga25_CMYK_branco.png';
import investidorPTRed from 'assets/partners/braga25/braga25-red-pt.png';
import SquareSGreen from 'assets/SquareSGreen.svg';
import SquareSRed from 'assets/SquareSRed.svg';
import PropTypes from 'prop-types';
import MobileMenu from '../mobile-menu';



const Header = ({ home, tickets, artists, conference, partners, news, about, faqs, lang, data }) => {

  const language = lang;
  const classes = useStyles({ home, tickets, artists, conference, news, about, faqs });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const [isScrolled, setIsScrolled] = useState(false);
  const [switchLanguageLink, setSwitchLanguageLink] = useState(false);


  const languageMap = useMemo(() => ({
    '/': '/pt',
    '/pt': '/',
    '/artists': '/pt/artistas',
    '/pt/artistas': '/artists',
    '/artist': '/pt/artista',
    '/pt/artista': '/artist',
    '/conference': '/pt/conferencia',
    '/pt/conferencia': '/conference',
    '/partners': '/pt/parceiros',
    '/pt/parceiros': '/partners',
    '/partner': '/pt/parceiro',
    '/pt/parceiro': '/partner',
    '/speaker': '/pt/orador',
    '/pt/orador': '/speaker',
    '/terms-and-conditions': '/pt/terms-and-conditions',
    '/pt/terms-and-conditions': '/terms-and-conditions',
    '/tickets': '/pt/bilhetes',
    '/pt/bilhetes': '/tickets',
    '/faqs': '/pt/faqs',
    '/pt/faqs': '/faqs',
    '/news': '/pt/noticias',
    '/pt/noticias': '/news',
    '/event': '/pt/evento',
    '/pt/evento': '/event',
  }), []);

  const getLanguageRoute = useCallback((pathname) => {
    if (pathname === '/' || pathname === '/pt') {
      return languageMap[pathname];
    }

    const baseRoute = Object.keys(languageMap).find(route =>
      pathname.startsWith(route) && route !== '/' && route !== '/pt'
    );

    if (baseRoute) {
      const slug = pathname.replace(baseRoute, '');
      return languageMap[baseRoute] + slug;
    }
  }, [languageMap]);

  const location = useLocation();

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1024);
    setIsDesktop(window.innerWidth > 1024);
  };

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const validSlugs = data.filter(item => item.fields?.slug && item.fields?.slugPT);

      validSlugs.forEach(item => {
        languageMap[`/news/${item.fields.slug}`] = `/pt/noticias/${item.fields.slugPT}`;
        languageMap[`/pt/noticias/${item.fields.slugPT}`] = `/news/${item.fields.slug}`;
      });
    }

    setSwitchLanguageLink(getLanguageRoute(location.pathname));

  }, [data, location.pathname, getLanguageRoute, languageMap]);


  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      className={cx(
        classes.header,
        { home, tickets, conference, news, about, faqs },
        { scrolled: isScrolled },
        { scrolledMobile: isScrolled && isMobile },
        { 'theme-light': artists || partners }
      )}
    >
      {language === 'en' ? (
        <Link className={cx(classes.square, { scrolled: isScrolled })} to="/">
          <img style={{ width: '100%' }} src={(artists || partners) ? SquareSRed : SquareSGreen} alt="SquareLogo" />
        </Link>
      ) : (
        <Link className={cx(classes.square, { scrolled: isScrolled })} to="/pt">
          <img style={{ width: '100%' }} src={(artists || partners) ? SquareSRed : SquareSGreen} alt="SquareLogo" />
        </Link>
      )}


      <div className={classes.headerContent}>
        {(() => {
          let imgSrc;
          let linkTo;

          if (lang === 'en') {
            imgSrc = (artists || partners) ? investidorRed : investidor;
            linkTo = 'https://braga25.pt/en/';
          } else if (lang === 'pt') {
            imgSrc = (artists || partners) ? investidorPTRed : investidorPT;
            linkTo = 'https://braga25.pt';
          }

          return imgSrc ? (
            <a
              className={cx(classes.investidor, { scrolled: isScrolled })}
              href={linkTo}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={imgSrc} alt="braga25" />
            </a>
          ) : null;
        })()}

        {isDesktop && (
          <>
            <div
              className={cx(classes.navigation, { scrolled: isScrolled }, { scrolledArtists: isScrolled && artists })}
            >
              <div className={classes.navItem}>
                <Link to={lang === 'en' ? '/' : '/pt'}>Home</Link>
                {home && <div className="line" />}
              </div>

              <div className={classes.navItem}>
                <Link to={lang === 'en' ? '/tickets' : '/pt/bilhetes'}>
                  {language === 'en' ? 'Tickets' : 'Bilhetes'}
                </Link>
                {tickets && <div className="line" />}
              </div>

              <div className={cx(classes.navItem, { artists })}>
                <Link to={lang === 'en' ? '/artists' : '/pt/artistas'}>{lang === 'en' ? 'Festival' : 'Festival'}</Link>
                {artists && <div className={cx('line', 'artists')} />}
              </div>

              <div className={cx(classes.navItem, { conference })}>
                <Link to={lang === 'en' ? '/conference' : '/pt/conferencia'}>
                  {language === 'en' ? 'Conference' : 'Conferência'}
                </Link>
                {conference && <div className="line" />}
              </div>

              <div className={cx(classes.navItem, { partners })}>
                <Link to={lang === 'en' ? '/partners' : '/pt/parceiros'}>
                  {language === 'en' ? 'Partners' : 'Parceiros'}
                </Link>
                {partners && <div className="line" />}
              </div>

              <div className={classes.navItem}>
                <Link to={lang === 'en' ? '/news' : '/pt/noticias'}>{language === 'en' ? 'News' : 'Notícias'}</Link>
                {news && <div className="line" />}
              </div>

              <div className={classes.navItem}>
                <Link to={lang === 'en' ? '/faqs' : '/pt/faqs'}>FAQs</Link>
                {faqs && <div className="line" />}
              </div>
            </div>

            {language === 'en' ? (
              <Link className={classes.heroLanguageChanger} to={switchLanguageLink}>
                <img src={pt} alt="pt" />
              </Link>
            ) : (
              <Link className={classes.heroLanguageChanger} to={switchLanguageLink}>
                <img src={en} alt="en" />
              </Link>
            )}
          </>
        )}

        {isMobile && (
          <MobileMenu
            home={home}
            tickets={tickets}
            artists={artists}
            conference={conference}
            partners={partners}
            news={news}
            about={about}
            faqs={faqs}
            lang={language}
            link={switchLanguageLink}
          />
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  home: PropTypes.bool,
  tickets: PropTypes.bool,
  artists: PropTypes.bool,
  conference: PropTypes.bool,
  news: PropTypes.bool,
  about: PropTypes.bool,
  faqs: PropTypes.bool,
  lang: PropTypes.string,
};

export default Header;
