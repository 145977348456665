import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  artists: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33.33%',
    padding: '3rem',
    color: 'var(--red)',
    '& img': {
      height: '250px',
      objectFit: 'contain',
    },
    '& img.big': {
      height: '4.2cm',
      padding: 0,
    },
  },
  artistTitle: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: 32,
    textAlign: 'center',
    marginTop: '1rem',
    '& span': {
      fontSize: '16px',
    },
  },
  artistAbout: {
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: 0.4,
    textAlign: 'center',
    marginTop: '1rem',
    '& span': {
      fontSize: '16px',
    },
  },
  detailButton: {
    marginTop: '10px',
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
  '@media (max-width: 1024px)': {
    container: {
      width: "100%",
      padding: '120px 1.5rem 80px 1.5rem',
    },
    artists: {
      width: '50%',
    },
    artistsSection: {
      padding: '4rem 1rem',
    },
  },
  '@media (max-width: 560px)': {
    artists: {
      width: '100%',
    },
  },
});

export default useStyles;
