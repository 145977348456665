import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  header: {
    position: 'fixed',
    top: 0,
    width: '100%',
    maxHeight: '100vh',
    color: 'white',
    zIndex: '100',
    backgroundColor: 'rgba(64, 50, 13, 0)',
    transition: 'all 0.35s cubic-bezier(0.6, -0.28, 0.74, 0.05)',
    '&.theme-light': {
      color: '#40320D',
    },
    '&.scrolledMobile.scrolled': {
      /* backgroundColor: 'rgba(46, 41, 35, 1)', */
      transition:
        'all 0.200s linear(0 0%, 0 1.8%, 0.01 3.6%, 0.03 6.35%, 0.07 9.1%, 0.13 11.4%, 0.19 13.4%, 0.27 15%, 0.34 16.1%, 0.54 18.35%, 0.66 20.6%, 0.72 22.4%, 0.77 24.6%, 0.81 27.3%, 0.85 30.4%, 0.88 35.1%, 0.92 40.6%, 0.94 47.2%, 0.96 55%, 0.98 64%, 0.99 74.4%, 1 86.4%, 1 100%)',
      backgroundColor: 'var(--main-background-color)',
      color: 'white',
    },
    '&.scrolledMobile.theme-light': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      color: 'var(--var)',
    },
  },

  heroLanguageChanger: {
    width: '44px',
    fontSize: '18px',
    textAlign: 'right',
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 2rem 0 2rem',
    minHeight: '110px',
  },

  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '500px',
    width: '80%',
    margin: '3rem 0',
    cursor: 'pointer',
  },
  investidor: {
    width: '152px',
    transform: 'translate(0%,0px)',
    transition: 'all 0.5s cubic-bezier(0.6, -0.28, 0.74, 0.05)',
    '&.scrolled': {
      transition:
        'all 0.5s linear(0 0%, 0 1.8%, 0.01 3.6%, 0.03 6.35%, 0.07 9.1%, 0.13 11.4%, 0.19 13.4%, 0.27 15%, 0.34 16.1%, 0.54 18.35%, 0.66 20.6%, 0.72 22.4%, 0.77 24.6%, 0.81 27.3%, 0.85 30.4%, 0.88 35.1%, 0.92 40.6%, 0.94 47.2%, 0.96 55%, 0.98 64%, 0.99 74.4%, 1 86.4%, 1 100%)',
      transform: 'translate(0%, -200px)',
    },
  },

  square: {
    position: 'absolute',
    top: 'calc(0% - 0px)',
    left: '2rem',
    width: '80px',
    cursor: 'pointer',
    transform: 'translate(0%, -100%)',
    transition: 'all 0.5s cubic-bezier(0.6, -0.28, 0.74, 0.05)',
    '&.scrolled': {
      transition:
        'all 0.5s linear(0 0%, 0 1.8%, 0.01 3.6%, 0.03 6.35%, 0.07 9.1%, 0.13 11.4%, 0.19 13.4%, 0.27 15%, 0.34 16.1%, 0.54 18.35%, 0.66 20.6%, 0.72 22.4%, 0.77 24.6%, 0.81 27.3%, 0.85 30.4%, 0.88 35.1%, 0.92 40.6%, 0.94 47.2%, 0.96 55%, 0.98 64%, 0.99 74.4%, 1 86.4%, 1 100%)',
      top: 'calc(0% + 16px)',
      transform: 'translate(0%, 0%)',
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#181818',
    opacity: 0.5,
    pointerEvents: 'none',
  },
  navigation: {
    margin: '0 auto',
    padding: '0 2rem',
    borderRadius: '24px',
    border: '2px solid rgba(57, 255, 137, 0)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '72px',
    fontSize: '18px',
    fontWeight: '600',
    backgroundColor: 'rgba(64, 50, 12, 0)',
    transition: 'all 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96)',
    gap: 24,
    '&.scrolled': {
      backgroundColor: 'var(--main-background-color)',
      color: 'white',
    },
    '&.scrolledArtists': {
      backgroundColor: 'rgba(255, 255, 255, 100)',
      color: 'var(--var)',
    },
  },
  navItem: {
    width: 'auto',
    position: 'relative',
    cursor: 'pointer',
    '& a': {
      textDecoration: 'none',
    },
    '& .line': {
      position: 'absolute',
      top: '100%',
      left: 0,
      width: '100%',
      height: '2px',
      backgroundColor: 'var(--green)',
    },
    '&.conference': {
      color: 'var(--green)',
    },
    '&.artists': {
      color: 'var(--red)',
    },
    '&.artists .line': {
      background: 'var(--red)',
    },
    '&.partners .line': {
      background: 'var(--red)',
    },
  },
  '@media (max-width: 1024px)': {
    headerContent: {
      padding: '1rem 1.5rem 1rem 1.5rem',
    },
    videoMobile: {
      display: 'block',
    },
    videoDesktop: {
      display: 'none',
    },
    square: {
      width: '64px',
      left: '24px',
      '&.scrolled': {
        top: 'calc(0% + 12px)',
      },
    },
    investidor: {
      width: '120px',
      '&.scrolled': {
        transform: 'translate(0%, -200px)',
      },
    },
  },
});

export default useStyles;
