import React from 'react';
import { Link } from 'react-router-dom';

import useStyles from './styles.js';
import Button from 'components/button/index.js';
import { useQuery } from '@tanstack/react-query';
import { airtableApi } from '../../services/api';
import PropTypes from 'prop-types';
import ArtistCard from 'components/artist-card';

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

const ArtistasList = ({ lang }) => {
  const classes = useStyles();

  const { data, error, isLoading } = useQuery({
    queryKey: ['artistas'],
    queryFn: airtableApi.getArtists,
  });

  return (
    <section className={classes.artistsSection}>
      {lang === 'en' && <p className={classes.artistsTitle}>Confirmed Artists</p>}
      {lang === 'pt' && <p className={classes.artistsTitle}>Artistas confirmados</p>}

      <div className={classes.maxWidth}>
        {isLoading && <p>Loading...</p>}
        {error && <p>Error loading data: {error.message}</p>}

        {!isLoading && !error && (
          <>
            <div className={classes.artistsList}>
              {shuffleArray(data)
                .slice(0, 3)
                .map((artista) => (
                  <ArtistCard key={artista.id} artist={artista} lang={lang} />
                ))}
            </div>

            <Link to="/artists" className="no-underline">
              <Button background="red" color="white" fontSize="24px">
                {lang === 'en' ? 'View All' : 'Ver Todos'}
              </Button>
            </Link>
          </>
        )}
      </div>
    </section>
  );
};

ArtistasList.propTypes = {
  lang: PropTypes.string,
};

export default ArtistasList;
